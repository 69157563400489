.pp {
  display: flex;
  justify-content: center;
  row-gap: 50px;
  margin: 10px 0;
}

.section-title {
  position: relative;
}
.section-title h2 {
  color: #1d2025;
  position: relative;
  margin: 0;
  font-size: 24px;
}
@media (min-width: 768px) {
  .section-title h2 {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .section-title h2 {
    font-size: 34px;
  }
}
.section-title.title-ex1 h2 {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .section-title.title-ex1 h2 {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .section-title.title-ex1 h2 {
    padding-bottom: 40px;
  }
}
.section-title.title-ex1 h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 110px;
  height: 1px;
  background-color: #d6dbe2;
}
@media (min-width: 768px) {
  .section-title.title-ex1 h2:before {
    bottom: 17px;
  }
}
@media (min-width: 992px) {
  .section-title.title-ex1 h2:before {
    bottom: 25px;
  }
}
.section-title.title-ex1 h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 40px;
  height: 1px;
  background-color: #0cc652;
}
@media (min-width: 768px) {
  .section-title.title-ex1 h2:after {
    bottom: 17px;
  }
}
@media (min-width: 992px) {
  .section-title.title-ex1 h2:after {
    bottom: 25px;
  }
}
.section-title.title-ex1.text-center h2:before {
  left: 50%;
  transform: translateX(-50%);
}
.section-title.title-ex1.text-center h2:after {
  left: 50%;
  transform: translateX(-50%);
}
.section-title.title-ex1.text-right h2:before {
  left: auto;
  right: 0;
}
.section-title.title-ex1.text-right h2:after {
  left: auto;
  right: 0;
}
.section-title.title-ex1 p {
  font-family: "Montserrat", sans-serif;
  color: #8b8e93;
  font-size: 14px;
  font-weight: 300;
}

.price-card {
  background: linear-gradient(
    180deg,
    rgba(31, 106, 248, 1) 0%,
    rgba(75, 208, 247, 1) 100%
  );
  padding: 40px 35px;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
}
.price-card:before {
  position: absolute;
  content: "";
  top: 0;
  right: -35px;
  width: 88px;
  height: 88px;
  background: #0cc652;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(45deg);
}
.price-card:after {
  position: absolute;
  content: "";
  top: 30px;
  right: -35px;
  width: 88px;
  height: 88px;
  background: #0cc652;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(45deg);
}
.price-card h2 {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
}
.price-card .btn {
  font-size: 11px;
  border-radius: 100px;
  padding: 0 25px;
  border: 0;
  color: #fff;
  float: right;
}
.price-card .btn.btn-primary {
  border: 0 !important;
}
.price-card.featured {
  background: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}
.price-card:hover .btn {
  background: #0cc652;
  border-color: #0cc652;
}
p.price span {
  display: inline-block;
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  position: relative;
}
/* p.price span:before {
  position: absolute;
  content: "$";
  font-size: 16px;
  top: 25px;
  font-weight: 300;
  left: 0;
} */

.pricing-offers::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}

.pricing-offers::-webkit-scrollbar-thumb {
  background-color: blue; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  /* border: 3px solid orange; */
  /* creates padding around scroll thumb */
}

.pricing-offers {
  padding: 0 0 10px;
  height: 250px;
  overflow: auto;
}
.pricing-offers li {
  padding: 0 0 16px;
  line-height: 18px;
}
ul li {
  list-style-type: none;
}
.btn.btn-mid {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
