@import "https://unpkg.com/open-props";

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.montserrat-alternates-thin {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-alternates-extralight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-alternates-light {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-alternates-regular {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-alternates-medium {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-alternates-semibold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-alternates-bold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-alternates-extrabold {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-alternates-black {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-alternates-thin-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-alternates-extralight-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-alternates-light-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-alternates-regular-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-alternates-medium-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-alternates-semibold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-alternates-bold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-alternates-extrabold-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-alternates-black-italic {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.App {
  text-align: center;
}

.tel {
  color: #fff;
  list-style: none;
  text-decoration: none;
}
.tel:hover {
  color: #fff;
}

.cont {
  width: 100%;
  margin: auto;
  background-color: #212529;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff !important;
  background-color: #1d6dfa !important;
}
.subtitle {
  color: #0d6efd;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
}
.hero {
  background-image: url("./assets/banner.webp");
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  .herop {
    padding-left: 4vw;

    color: #fff;
    margin-bottom: -2px;
  }

  .herotitle {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    padding-left: 4vw;
    padding-right: 55vw;
    font-size: 2.5em;
    color: #fff;
  }
  .herotxt {
    font-size: 1.05rem;
    color: #fff;
    padding-left: 4vw;
    padding-right: 50vw;
    overflow: hidden;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .hbtn {
    margin-left: 4vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
.hbtn button {
  margin-right: 15px;
}
.hbtn .hlink {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: #fff;
  padding: 10px 25px;
}

/* Page Title Banner */

.pagebanner {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  border-radius: 0% 0% 50% 50%;
  background-color: #0096ff;
}
.bannertitle {
  color: #fff;
  font-size: 5em;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/* Page Title Banner End */

.sergrd {
  width: 90;
  padding: 10px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.ser {
  margin: 50px;
}
.services {
  width: 24%;
  padding: 50px;
  background-color: #0d6efd;
  border-radius: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 5px 5px #212529;
  }
}

.web {
  background-image: url("./assets/web.webp");
  background-size: cover;

  /* transition: ease-in-out; */
}
.market {
  background-image: url("./assets/marketing.webp");
  background-size: cover;
}
.it {
  background-image: url("../public/IT.png");
  background-size: cover;
}
.shop {
  background-image: url("./assets/shops.webp");
  background-size: cover;
}

.abt {
  padding: 15vh 5vw;
  display: flex;
  background: rgb(6, 59, 116);
  background: linear-gradient(
    14deg,
    rgba(6, 59, 116, 1) 27%,
    rgba(6, 56, 114, 1) 77%
  );

  /* background-color: #0d6efd; */
}
.abtimg {
  width: 55%;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  position: relative;
  .years {
    position: absolute;
    background-color: #ffffff72;
    padding: 10px 50px;
    border-radius: 20px;
    bottom: -50px;
    right: -5px;
    box-shadow: 5px 5px 10px #fff;
  }
}
.abttxt {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  p {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    font-weight: 200;
    color: #fff;
  }
}

.abtimg img {
  width: 80%;
  height: 600px;
  border-radius: 15px;
}

.testi {
  width: 90%;
  margin: auto;
  .carousel-item {
    background-color: #61dafb;
    padding: 20px;
  }
}

/* Approch sec */
.aproch {
  padding: 12vh 5vw;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .aprochimg {
    width: 40%;
  }
  .approchdetails {
    width: 55%;
  }
  .aprochimg img {
    width: 100%;
    border-radius: 12px;
  }
}
.aproch p {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.aproch h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.cta {
  background-image: url("../public/cta.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 500px;
  background-position: fixed;
}
.ctap {
  font-size: 16px;
  color: #fff;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.ctah {
  font-size: 38px;
  text-align: center;
  padding: 0px 180px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: capitalize;
}

/* End Approch Sec */

/* About Page */

/* .about {
  display: flex;
  padding-top: 100px;

  .about1,
  .about2 {
    width: 48%;
    padding: 2%;
  }
} */
.progresss p {
  font-size: 20px;
}

/* About Page */

/* Footer */
.foot {
  padding: 50px 10px;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}
.foo {
  width: 25%;
  padding-left: 15px;
}
.foosec {
  width: 92%;
  margin: auto;
  display: flex;
}
.footlink ul {
  list-style: none;
  text-decoration: none;
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -30px;
}
.footh {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}
.footlink ul li {
  padding: 3px 0;
}
.footlink ul li a {
  list-style: none;
  text-decoration: none;
  color: #fff;
}

.postpagetop {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}
.postpage {
  width: 90%;
  margin: auto;
  padding: 10px 0;
}

@media screen and (max-width: 480px) {
  /* hero sec */

  .hero {
    background-image: url("./assets/banner.webp");
    width: 100%;
    height: 280px;
    background-size: cover;

    .herotitle {
      padding-top: 2vw;
      padding-right: 2vw;
      font-size: 20em;
      color: #fff;
      font-size: 2em;
    }
    .herotxt {
      font-size: 0.8em;
      color: #fff;
      padding-left: 4vw;
      padding-right: 5vw;
      overflow: hidden;
    }
    .hbtn {
      display: none;
    }
  }

  /* hero sec end */

  /* Page Title Banner */

  .pagebanner {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    border-radius: 0% 0% 50% 50%;
    background-color: #0096ff;
  }
  .bannertitle {
    color: #fff;
    font-size: 3em;
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  /* Page Title Banner End */

  /* Services */
  .ser {
    margin: 20px;
  }
  .sergrd {
    width: 90;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .services {
    width: 95%;
    margin-bottom: 10px;
    padding: 50px;
    background-color: #0d6efd;
  }
  /* Services End */

  .abt {
    padding: 2vh 2vw;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    background-color: #0d6efd;
  }
  .abtimg {
    margin-right: 2vw;
    margin-bottom: 2vh;
    width: 90%;
    border-radius: 15px;
  }
  .abttxt {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h5 {
      font-size: 32px;
      font-weight: 700;
      color: #fff;
    }
    p {
      font-size: 18px;
      font-weight: 200;
      color: #fff;
    }
  }

  .abtimg img {
    width: 100%;
    height: 600px;
    border-radius: 15px;
  }

  /* Approch sec */
  .aproch {
    padding: 5vh 5vw;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    .aprochimg {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .approchdetails {
      width: 95%;
      margin-top: 20px;
    }
    .aprochimg img {
      width: 100%;
    }
  }
  /* End Approch Sec */

  /* Call To Action */

  .cta {
    height: 250px;
    background-size: cover;
  }
  .ctah {
    font-size: 18px;
    padding: 0 5px;
  }
  /* About page */
  .about {
    display: flex;
    padding-top: 100px;
    flex-wrap: wrap;
    .about1,
    .about2 {
      width: 90%;
      padding: 2%;
    }
  }
  /* About page */
  /* Footer */
  .foot {
    display: flex;
    flex-wrap: wrap;
  }
  .footlogo {
    width: 100%;
  }
  .footlogo img {
    width: 100%;
    height: 120px;
  }
  .footlink {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  ul {
    list-style: none;
  }

  .content {
    background-color: #1ebea5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title {
    color: white;
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 40pt;
  }

  .foot {
    padding: 50px 10px;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
  }
  .foo {
    width: 100%;
    padding-left: 15px;
  }
  .foosec {
    width: 92%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .footlink ul {
    list-style: none;
    text-decoration: none;
    justify-content: start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: -30px;
  }
  .footh {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .footlink ul li {
    padding: 3px 0;
  }
  .footlink ul li a {
    list-style: none;
    text-decoration: none;
    color: #fff;
  }

  .tawk-min-container,
  .tawk-min-container .tawk-button {
    display: none;
  }
}
