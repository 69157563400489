.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.signika-Medium {
  font-family: "Signika", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "GRAD" 0;
}

.headban {
  background-color: #212529;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  gap: 8px;
  h3 {
    font-size: 100px;
  }
  .shead {
    font-size: 44px;
    padding: 0 200px;
    text-align: center;
  }
  .hbtn {
    background-color: #4cd3f7;
    padding: 10px 100px;
    border-radius: 100px;
    font-size: 80px;
    color: #212529;
  }
  p {
    padding: 0 250px;
    font-size: 20px;
    text-align: center;
  }
}

.videosec {
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
}

.technology {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #212529;
  padding: 50px;
  p {
    font-size: 18px;
  }
  h4 {
    color: #fff;
    font-size: 34px;
    font-weight: 200;
    padding-bottom: 30px;
  }
}
.technologyimg {
  display: flex;
  width: 100%;
}
.technologyimg1 {
  width: 40%;
  margin-right: 10px;
  margin: auto;
}

.foothead {
  background-color: #212529;
  display: flex;
  justify-content: center;
}

.footheading {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 12em;
  color: #4cd3f7;
}

.ourproduct,
.casestudy {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #212529;
  padding: 50px;
  p {
    font-size: 18px;
    color: #4cd3f7;
  }
  h4 {
    color: #fff;
    font-size: 34px;
    font-weight: 200;
    padding-bottom: 30px;
  }
}
.proimg,
.casestudyimg {
  display: flex;
  width: 100%;
}
.proimg1 {
  width: 40%;
  margin-right: 10px;
  margin: auto;
}

.technologyimg img,
.proimg img,
.casestudyimg img {
  width: 100%;
  border-radius: 20px;
}
