.countercon {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .countercon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
  }
}
