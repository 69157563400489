.about {
  display: flex;
  padding-top: 100px;
  width: 90%;
  margin: auto;

  .about1,
  .about2 {
    width: 48%;
  }
}

p {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;

  letter-spacing: 1px;
}
h2,
h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@media screen and (max-width: 480px) {
  .about {
    display: flex;
    padding-top: 100px;
    flex-wrap: wrap;
    .about1,
    .about2 {
      width: 90%;
      padding: 2%;
    }
  }
  .abtimg img {
    height: 400px;
  }
}
