.showcase {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .p {
    font-size: 16px;
  }
  .h3 {
    font-size: 28px;
  }
}
.show_subtitle {
  color: #1e68f8;
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.show_title {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.project {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  padding-top: 40px;
  .proimg {
    width: 40%;
  }
  .protxt {
    width: 40%;
    background-color: aliceblue;
    padding: 50px;
    justify-content: center;
    align-items: center;
    margin-left: -90px;
    border-radius: 12px;
    box-shadow: 0 0 15px whitesmoke;
    .show_subtitle,
    .show_des {
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
    .show_title {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
  }
}
.project2 {
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  align-items: center;
  .proimg {
    width: 40%;
  }
  .protxt {
    width: 40%;
    background-color: aliceblue;
    padding: 50px;
    justify-content: center;
    align-items: center;
    margin-right: -90px;
    z-index: 2;
    border-radius: 12px;
    box-shadow: 0 0 15px whitesmoke;
    .show_subtitle,
    .show_des {
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
    .show_title {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-style: normal;
    }
  }
}
.proimg img {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .showcase {
    width: 90%;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .p {
      font-size: 14px;
    }
    .h3 {
      font-size: 18px;
    }
  }

  .project {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    align-items: center;
    padding: 80px 0;
    .proimg {
      width: 90%;
      margin: auto;
    }
    .protxt {
      width: 90%;
      background-color: aliceblue;
      padding: 50px;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 12px;
      box-shadow: 0 0 15px whitesmoke;
    }
  }
  .project2 {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    .proimg {
      width: 90%;
      margin: auto;
    }
    .protxt {
      width: 90%;
      background-color: aliceblue;
      padding: 50px;
      justify-content: center;
      align-items: center;
      margin: auto;
      z-index: 2;
      border-radius: 12px;
      box-shadow: 0 0 15px whitesmoke;
    }
  }
}
