.ttestimonial {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
  justify-content: space-evenly;
}
.testi {
  border: 1px solid #b2beb5;
  box-shadow: 0px 0px 8px #e5e4e2;
  border-radius: 8px;
  padding: 15px 25px;
  width: 48%;
  margin: 10px;
}
@media screen and (max-width: 480px) {
  .ttestimonial {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 50px 0;
    justify-content: space-evenly;
  }
  .testi {
    border: 1px solid #b2beb5;
    box-shadow: 0px 0px 8px #e5e4e2;
    border-radius: 8px;
    padding: 15px 25px;
    width: 95%;
    margin: 10px;
  }
}
