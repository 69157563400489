@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.outfit-Medium {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.lfooter {
  padding: 50px 0;
  background-color: #121417;
  margin-top: -40px;
  .footer {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
  }
  .lfoot {
    flex-grow: 1;
    padding: 40px;
    flex-basis: 300px;
    li {
      list-style: none;
    }
    .link {
      font-family: "Outfit", sans-serif;
      font-optical-sizing: auto;
      font-weight: 200;
      font-style: normal;
      color: #fff;
      list-style: none;
      text-decoration: none;
    }
  }
}

.lfoot p .Link {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  color: #fff;
  text-decoration: none;
  list-style: none;
}

.footcontact {
  width: 80%;
  height: 100px;
  display: flex;
  background-color: #121417;
  margin: auto;
  border-radius: 12px;
  .footlogo {
    background-color: #0e59f2;
    height: 100%;
    width: 25%;
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 700;
    font-style: normal;
    color: #fff;
    font-size: 45px;
    border-radius: 12px 0px 0 12px;
  }
  .fcontact {
    display: flex;
    width: 25%;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px;
    .fii {
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50px;
      padding: 8px;
      gap: 12px;
    }

    .fcd {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      .ftxt {
        font-family: "Outfit", sans-serif;
        font-weight: 200;
        font-style: normal;
        font-size: 14px;
      }
    }
  }
}

.service {
  width: 80%;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .serheading {
    font-family: "Outfit", sans-serif;
    font-size: 42px;
    font-weight: 500;
    font-style: normal;
  }
  .ser {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    .servicebox {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      width: 30%;
      padding: 10px;
      border-radius: 12px;
      border: 1px solid #121417;
      img {
        width: 100%;
      }

      .serviceheading {
        font-family: "Outfit", sans-serif;
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
      }
      .servicetxt {
        text-align: center;
        margin-top: -10px;
      }
    }
  }
}

.servicebox:hover {
  box-shadow: 2px 2px 15px rgb(199, 199, 199);
}

.faq1 {
  margin-top: -50px;
}

.testbanner {
  background-image: url("../assets/Section.png");
  background-position: center;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tsubh {
    font-family: "Outfit", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
  }
  .thead {
    font-family: "Outfit", sans-serif;
    font-size: 48px;
    font-weight: 500;
    font-style: normal;
  }
  .tsubj {
    font-family: "Outfit", sans-serif;
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
  }
  .tcontent {
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    text-align: center;
    padding: 0 350px;
    font-weight: 300;
    font-style: normal;
  }
}

.ctaa {
  background-image: url("../assets/Sectionn.png");
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 200px;
  gap: 15px;
  color: #fff;
  h3 {
    font-family: "Outfit", sans-serif;
    font-size: 42px;
    font-weight: 500;
    font-style: normal;
  }
  p {
    text-align: center;
  }
}

.ms {
  background-color: #eff2f8;
  height: 100%;
  justify-content: center;
  display: flex;
}

.service {
  width: 90%;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ser {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    .service1 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      width: 20%;
      box-shadow: 2px 2px 10px rgb(249, 249, 249);
      padding: 20px;
      border-radius: 12px;
      background-color: #fff;
      img {
        width: 30%;
      }

      .serviceheading {
        font-family: "Outfit", sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
      }
      .servicetxt {
        margin-top: -10px;
      }
    }
  }
}

.tservice {
  width: 80%;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .serheading {
    font-family: "Outfit", sans-serif;
    font-size: 42px;
    font-weight: 500;
    font-style: normal;
  }
  .ser {
    width: 90%;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .servicebox {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      width: 24%;
      padding: 10px;
      border-radius: 12px;
      border: 1px solid #121417;
      img {
        width: 100%;
      }

      .serviceheading {
        font-family: "Outfit", sans-serif;
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
        text-align: center;
      }
      .servicetxt {
        text-align: center;
        margin-top: -10px;
      }
    }
  }
}

.heroban {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  z-index: 1;
  .herotxt {
    width: 50%;
    background-color: #eceefc;
    padding: 50px;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    h2 {
      font-family: "Outfit", sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;
      font-size: 60px;
      font-style: normal;
    }
    .hdes {
      font-family: "Outfit", sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-size: 18px;
      font-style: normal;
    }
    button {
      background-color: #0e59f2;
      border-radius: 50px;
      color: #fff;
      border: 0px;
      padding: 8px 25px;
    }
  }
  .heroimg {
    width: 50%;
    img {
      width: 100%;
    }
  }
}

.header {
  content: "";
  position: absolute;
  width: 100%;
  top: 20px;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  .container {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    width: 98%;
    .im {
      width: 55%;
      img {
        width: 100%;
      }
    }
    .landbtn {
      width: 45%;
      align-items: end;
      display: flex;
      justify-content: end;
      button {
        font-size: 14px;
      }
    }
  }
}

/* .header {
  content: "";
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: end;
  top: 20px;
  z-index: 9;
  .container {
    border-radius: 12px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
} */

@media (max-width: 480px) {
  .header {
    content: "";
    position: absolute;
    width: 100%;
    top: 20px;
    z-index: 9;
    display: flex;
    justify-content: space-around;
    .container {
      display: flex;
      justify-content: center;
      border-radius: 12px;
      width: 98%;
      .im {
        width: 55%;
        img {
          width: 100%;
        }
      }
      .landbtn {
        width: 45%;
        button {
          font-size: 14px;
        }
      }
    }
  }

  .heroban {
    display: flex;
    flex-wrap: wrap;
    .herotxt {
      width: 100%;
      h2 {
        padding-top: 120px;
        font-size: 38px;
      }
    }
    .heroimg {
      width: 100%;
    }
  }

  .ms img {
    width: 100%;
  }

  .service {
    background-color: #eff2f8;
    width: 100% !important;
    padding: 30px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ser {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .service1 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        width: 90%;
        box-shadow: 2px 2px 10px rgb(249, 249, 249);
        padding: 20px;
        border-radius: 12px;
        background-color: #fff;
        img {
          width: 30%;
        }

        .serviceheading {
          font-family: "Outfit", sans-serif;
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
        }
        .servicetxt {
          margin-top: -10px;
        }
      }
    }
  }

  .tservice {
    width: 80%;
    padding: 30px 0;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .serheading {
      font-family: "Outfit", sans-serif;
      font-size: 34px;
      font-weight: 500;
      font-style: normal;
    }
    .ser {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .servicebox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: start;
        margin-bottom: 20px;
        width: 100%;
        padding: 10px;
        border-radius: 12px;
        border: 1px solid #121417;
        img {
          width: 100%;
        }

        .serviceheading {
          font-family: "Outfit", sans-serif;
          font-size: 22px;
          font-weight: 500;
          font-style: normal;
        }
        .servicetxt {
          text-align: center;
          margin-top: -10px;
        }
      }
    }
  }

  .testbanner {
    background-image: url("../assets/Section.png");
    background-position: center;
    background-size: cover;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tsubh {
      font-family: "Outfit", sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
    .thead {
      font-family: "Outfit", sans-serif;
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
    }
    .tsubj {
      font-family: "Outfit", sans-serif;
      font-size: 22px;
      font-weight: 500;
      font-style: normal;
    }
    .tcontent {
      font-family: "Outfit", sans-serif;
      font-size: 16px;
      text-align: center;
      padding: 10px 10px;
      font-weight: 300;
      font-style: normal;
    }
  }

  .service {
    width: 80%;
    padding: 30px 0;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .serheading {
      font-family: "Outfit", sans-serif;
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
    }
    .ser {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      .servicebox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        padding: 10px;
        border-radius: 12px;
        border: 1px solid #121417;
        img {
          width: 100%;
        }

        .serviceheading {
          font-family: "Outfit", sans-serif;
          font-size: 22px;
          font-weight: 500;
          font-style: normal;
        }
        .servicetxt {
          text-align: center;
          margin-top: -10px;
        }
      }
    }
  }

  .ctaa {
    background-image: url("../assets/Sectionn.png");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px;
    gap: 5px;
    color: #fff;
    h3 {
      font-family: "Outfit", sans-serif;
      font-size: 22px;
      font-weight: 500;
      font-style: normal;
    }
    p {
      text-align: center;
      font-size: 12px;
    }
  }

  .footcontact {
    width: 80%;
    height: 100px;
    display: flex;
    background-color: #121417;
    margin: auto;
    border-radius: 12px;
    .footlogo {
      background-color: #0e59f2;
      height: 100%;
      width: 25%;
      font-family: "Outfit", sans-serif;
      font-optical-sizing: auto;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 700;
      font-style: normal;
      color: #fff;
      font-size: 15px;
      border-radius: 12px 0px 0 12px;
    }
    .fcontact {
      display: flex;
      flex-wrap: wrap;

      .ficon {
        display: none;
        width: 01%;
        display: flex;
        justify-content: center;
        align-items: center;
        .fii {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 50px;
          padding: 10px;
        }
      }
      .fcd {
        width: 95%;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        color: #fff;
        .ftxt {
          font-family: "Outfit", sans-serif;
          font-weight: 200;
          font-style: normal;
          text-align: center;
          font-size: 7px;
        }
        .fp {
          text-align: center;
          font-size: 8px;
        }
      }
    }
  }
}
